import { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { commonApi } from "../../../constants/apis/adminCommunication";
import { RoundedBtn } from "../../../commanComponents/buttons/SubmitBtns";

const GarbageCollectionFilterForm = ({
  onClose,
  handleClick,
  selectedZoneList, // Corrected to selectedZoneList
  setSelectedZoneList, // Corrected to setSelectedZoneList
  selectedWardList,
  setSelectedWardList,
  zoneList, 
  setZoneList
}) => {
  const [wardList, setWardList] = useState([]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      padding: "0 1rem",
      minWidth: "200px",
      margin: "10px auto",
      textAlign: "left",
    }),
    menu: (provided) => ({
      ...provided,
      textAlign: "left",
      padding: "0 0", // Add padding to the menu
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0", // Add padding to the dropdown indicator
    }),
  };

  

  const getWardList = async () => {
    try {
      const res = await commonApi.getWardByZoneId(selectedZoneList?.value);
      if (res?.status === "SUCCESS") {
        const options = res.zone.map((ward) => ({
          value: ward._id,
          label: ward.name,
        }));
        setWardList(options);
      } else if (res?.status === "JWT_INVALID") {
        toast.error(res?.message || "Please Log In!");
        setWardList([]);
      } else {
        setWardList([]);
        toast.error(res?.message || "Something Went Wrong!");
      }
    } catch (error) {
      setWardList([]);
      toast.error(error?.message || "Something Went Wrong!");
    }
  };


  useEffect(() => {
    if (selectedZoneList) {
      if(selectedZoneList?.value){
        getWardList();
      }
    }
  }, [selectedZoneList]);

  return (
    <div className="px-2 mx-auto" style={{ maxWidth: "300px" }}>
      <Select
        value={selectedZoneList || ""}
        onChange={(selected) => {
          setSelectedZoneList(selected);
        }}
        options={[{ label: "Select All", value: "" }, ...zoneList]}
        styles={customStyles}
        placeholder="Select Zone"
      />
      <Select
        options={[{ label: "Select All", value: "" }, ...wardList]}
        value={selectedWardList || ""}
        onChange={(selected) => {
          setSelectedWardList(selected);
        }}
        styles={customStyles}
        placeholder="Select Ward"
      />
      <div
        className="d-flex align-items-center py-3 justify-content-center buttonsWrapper"
        style={{ gap: "10px" }}
      >
        <RoundedBtn
          text="Apply"
          className="bg_primary text-white px-4"
          type="button"
          onClick={handleClick}
        />
        <RoundedBtn
            text="Reset"
            className="btn-outline-secondary px-4 mr-3"
            type="button"
            onClick={()=>{
              setSelectedZoneList({label:'Select All', value:''})
              setSelectedWardList({label:'Select All', value:''})
              handleClick()
            }}
         />
        <RoundedBtn
          text="Close"
          className="bg-secondary text-white px-4"
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default GarbageCollectionFilterForm;
