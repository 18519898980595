import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
   commonApi,
   getServerUrl,
} from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import "./surveyManagement.css";
import Loader from "../../commanComponents/Loader";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import Attachment from "../../commanComponents/Attachment";
import { userRole } from "../../constants/Helper/getUserRole";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";

export default function ViewSurvey() {
   const navgation = useNavigate();
   const [params] = useSearchParams();
   const [loading, setLoading] = useState(false);
   const surveyDetailId = params.get("surveyDetailId");
   const flatId = params.get("flatId");
   const [details, setDetails] = useState({});
   const [surveyDetail, setSurveyDetail] = useState({});
   const [isPopupOpen, setIsPopupOpen] = useState(false);
   const [viewDocumentFile, setViewDocumentFile] = useState("");

   const handleClosePopup = () => setIsPopupOpen(false);

   const fetchSurveyDetailsByID = async (id) => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.getSurveyById(id);

         if (serverResponse?.status === "SUCCESS") {
            setDetails(serverResponse?.result);
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const handleFlatFamilyDetails = () => {
      const foundData = details?.ownersInfo?.find((ele) => ele?.id === flatId);
      if (foundData) {
         const data = { ...foundData };
         if (data?.families) {
            data.familyInfo = data.families;
            delete data.families;
         }
         setSurveyDetail(data);
      }
   };

   useEffect(() => {
      if (surveyDetailId) {
         fetchSurveyDetailsByID(surveyDetailId);
      }
   }, [surveyDetailId]);

   useEffect(() => {
      if (flatId) {
         handleFlatFamilyDetails();
      } else {
         setSurveyDetail(details);
      }
   }, [flatId, details]);

   useEffect(()=>{
      if (userRole() !=='zonal' && !checkUserTabAccess("Survey_Detail")) {
         toast.info('You do not have permission to access Survey Detail')
         navgation(-1); // redirect to previous page if user dont not have Tab Access
        return;
     }
     },[])

   return (
      <>
         {loading && <Loader />}
         <div className="tableWrapper big_Shadow p-4 ">
            <h3 className="text-center mb-4">Details</h3>
            <div className="YellowCardWrapper">
               <h5>-- Basic Details --</h5>
               <div className="besicDetailsWrapper">
                  <div className="infoTextWrapper">
                     <h6>Plot/House No:</h6>
                     <p className="text-capitalize">
                        {surveyDetail?.plotNo || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>House Type:</h6>
                     <p className="text-capitalize">
                        {details?.houseType || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Geo Tag:</h6>
                     <p className="text-capitalize">
                        {`${surveyDetail?.latitude || "--"}, ${
                           surveyDetail?.longitude || "--"
                        }`}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Mobile:</h6>
                     <p className="text-capitalize">
                        {surveyDetail?.mobile || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Caste:</h6>
                     <p className="text-capitalize">
                        {surveyDetail?.caste || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Family Member Count:</h6>
                     <p className="text-capitalize">
                        {surveyDetail?.familyMemberCount || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Family Living Count:</h6>
                     <p className="text-capitalize">
                        {surveyDetail?.familyLivingCount || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Water Tank Last Clean Date:</h6>
                     <p className="text-capitalize">
                        {(details?.waterTankLastCleanDate &&
                           new Date(
                              details?.waterTankLastCleanDate
                           ).toLocaleDateString("hi")) ||
                           "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Water Tank Next Clean Date:</h6>
                     <p className="text-capitalize">
                        {(details?.waterTankNextCleanDate &&
                           new Date(
                              details?.waterTankNextCleanDate
                           ).toLocaleDateString("hi")) ||
                           "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Safety Tank Last Clean Date:</h6>
                     <p className="text-capitalize">
                        {(details?.safetyTankLastCleanDate &&
                           new Date(
                              details?.safetyTankLastCleanDate
                           ).toLocaleDateString("hi")) ||
                           "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Safety Tank Next Clean Date:</h6>
                     <p className="text-capitalize">
                        {(details?.safetyTankNextCleanDate &&
                           new Date(
                              details?.safetyTankNextCleanDate
                           ).toLocaleDateString("hi")) ||
                           "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Is Bin Available:</h6>
                     <p className="text-capitalize">
                        {details?.isBinExist ? "Yes" : "No" || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>House Tax No:</h6>
                     <p className="text-capitalize">
                        {surveyDetail?.houseTaxNo || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Electric Bill No:</h6>
                     <p className="text-capitalize">
                        {surveyDetail?.electricBillNo || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Water Bill No:</h6>
                     <p className="text-capitalize">
                        {surveyDetail?.waterBillNo || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Apartment Name:</h6>
                     <p className="text-capitalize">
                        {details?.apartmentName || "--"}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Expected Dry Weight:</h6>
                     <p className="text-capitalize">
                        {`${surveyDetail?.expectedDryWeight?.weight || "--"} ${
                           surveyDetail?.expectedDryWeight?.unit || "--"
                        }`}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Expected Wet Weight:</h6>
                     <p className="text-capitalize">
                        {`${surveyDetail?.expectedWetWeight?.weight || "--"} ${
                           surveyDetail?.expectedWetWeight?.unit || "--"
                        }`}
                     </p>
                  </div>
                  <div className="infoTextWrapper">
                     <h6>Address:</h6>
                     <p className="text-capitalize">
                        {details?.address || "--"}
                     </p>
                  </div>
               </div>
            </div>

            <div className="YellowCardWrapper">
               <h5>-- Family Details --</h5>
               <div className="familyDetailsWrapper">
                  {surveyDetail?.familyInfo?.length > 0 ? (
                     surveyDetail?.familyInfo?.map((familyDetail, index) => {
                        return (
                           <div
                              style={{
                                 border:
                                    surveyDetail?.familyInfo?.length - 1 ==
                                       index && "none",
                                 padding:
                                    surveyDetail?.familyInfo?.length - 1 ==
                                       index && "0px",
                              }}
                           >
                              <div>
                                 <div className="infoTextWrapper">
                                    <h6>Name:</h6>
                                    <p className="text-capitalize">
                                       {familyDetail?.name || "--"}
                                    </p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Gender:</h6>
                                    <p className="text-capitalize">
                                       {familyDetail?.gender || "--"}
                                    </p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Email:</h6>
                                    <p>{familyDetail?.email || "--"}</p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Age:</h6>
                                    <p className="text-capitalize">
                                       {familyDetail?.age || "--"}
                                    </p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Aadhar Number:</h6>
                                    <p className="text-capitalize">
                                       {familyDetail?.aadharNumber || "--"}
                                    </p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Voter Id Number:</h6>
                                    <p className="text-capitalize">
                                       {familyDetail?.voterIdNumber || "--"}
                                    </p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Is Ration Card Available:</h6>
                                    <p className="text-capitalize">
                                       {familyDetail?.isRationExist
                                          ? "Yes"
                                          : "No" || "--"}
                                    </p>
                                 </div>
                              </div>
                              <div className="documentWrapper">
                                 <h6 className="text-capitalize">Documents</h6>
                                 <div>
                                    {familyDetail?.document?.length > 0 &&
                                       familyDetail?.document?.map(
                                          (data, index) => {
                                             return (
                                                <div className="AttachmentImageWrapper">
                                                   <img
                                                      onClick={() => {
                                                         setViewDocumentFile(
                                                            data?.fileUrl
                                                         );
                                                         setIsPopupOpen(true);
                                                      }}
                                                      alt={"Attachment"}
                                                      src={
                                                         typeof data?.fileUrl ===
                                                         "object"
                                                            ? URL.createObjectURL(
                                                                 data?.fileUrl
                                                              )
                                                            : `${getServerUrl()}/getFiles/${
                                                                 data?.fileUrl
                                                              }`
                                                      }
                                                   />
                                                </div>
                                             );
                                          }
                                       )}
                                 </div>
                              </div>
                           </div>
                        );
                     })
                  ) : (
                     <h6 className="DataNotFoundText">
                        No Family Details Available...
                     </h6>
                  )}
               </div>
            </div>
            <div className="YellowCardWrapper">
               <h5>-- Tree Details --</h5>
               <div className="familyDetailsWrapper">
                  {details?.treesInfo?.length > 0 ? (
                     details?.treesInfo?.map((tree, index) => {
                        return (
                           <div
                              style={{
                                 border:
                                    surveyDetail?.treesInfo?.length - 1 ==
                                       index && "none",
                                 padding:
                                    surveyDetail?.treesInfo?.length - 1 ==
                                       index && "0px",
                              }}
                           >
                              <div>
                                 <div className="infoTextWrapper">
                                    <h6>Tree Name:</h6>
                                    <p className="text-capitalize">
                                       {tree?.name || "--"}
                                    </p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Tree height:</h6>
                                    <p className="text-capitalize">
                                       {tree?.height || "--"}
                                    </p>
                                 </div>
                                 <div className="infoTextWrapper">
                                    <h6>Tree Age:</h6>
                                    <p className="text-capitalize">
                                       {tree?.age || "--"}
                                    </p>
                                 </div>
                              </div>
                              <div className="documentWrapper">
                                 <h6 className="text-capitalize">Tree Image</h6>
                                 <div>
                                    {
                                       // familyDetail?.document?.length >0 &&
                                       tree?.document?.map((data, index) => {
                                          return (
                                             <div className="AttachmentImageWrapper">
                                                <img
                                                   onClick={() => {
                                                      setViewDocumentFile(
                                                         data?.fileUrl
                                                      );
                                                      setIsPopupOpen(true);
                                                   }}
                                                   alt={"Attachment"}
                                                   src={
                                                      typeof data?.fileUrl ===
                                                      "object"
                                                         ? URL.createObjectURL(
                                                              data?.fileUrl
                                                           )
                                                         : `${getServerUrl()}/getFiles/${
                                                              data?.fileUrl
                                                           }`
                                                   }
                                                />
                                             </div>
                                          );
                                       })
                                    }
                                 </div>
                              </div>
                           </div>
                        );
                     })
                  ) : (
                     <h6 className="DataNotFoundText">
                        No Family Details Available...
                     </h6>
                  )}
               </div>
            </div>
         </div>

         <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            title="Document"
         >
            <Attachment onClose={handleClosePopup} fileUrl={viewDocumentFile} />
         </CenteredPopup>
      </>
   );
}
