import React, { useState } from "react";
import { RoundedBtn } from "../../commanComponents/buttons/SubmitBtns";
import Select from "react-select";
import { InputWithLabel } from "../../commanComponents/inputs/InputWithLabel";

const Filter = ({
   onClose,
   wardList,
   setPageUpdated,
   setPeriodOfContract,
   periodOfContract,
   setSelectedWard,
   selectedWard,
}) => {
   const PeriodOfContractList = [
      { label: "Select All", value: "" },
      { label: "1 Years", value: "1 years" },
      { label: "2 Years", value: "2 years" },
      { label: "3 Years", value: "3 years" },
      { label: "4 Years", value: "4 years" },
      { label: "5 Years", value: "5 years" },
      { label: "6 Years", value: "6 years" },
      { label: "7 Years", value: "7 years" },
      { label: "8 Years", value: "8 years" },
      { label: "9 Years", value: "9 years" },
      { label: "10 Years", value: "10 years" },
   ];

   return (
      <div>
         <div
            style={{
               textAlign: "start",
               width: "80%",
               margin: "auto",
               marginBottom: "20px ",
            }}
         >
            <label className="">Ward</label>
            <Select
               options={[{ label: "Select All", value: "" }, ...wardList]}
               onChange={(selected) => {
                  setSelectedWard(selected);
               }}
               placeholder="Select a Ward"
               value={selectedWard ? selectedWard : ""}
            />
         </div>
         <div
            style={{
               textAlign: "start",
               width: "80%",
               margin: "auto",
               marginBottom: "20px",
            }}
         >
            <label className="name text_color-lable">Period of Contract*</label>
            <Select
               options={PeriodOfContractList}
               onChange={(selected) => {
                  setPeriodOfContract(selected);
               }}
               value={selectedWard ? periodOfContract : ""}
               placeholder="Select a period of Contract"
            />
         </div>

         <div className="d-flex align-items-center py-3 justify-content-center buttonsWrapper">
            <RoundedBtn
               text="Apply"
               className="bg_primary text-white px-4 mr-3"
               type="button"
               onClick={() => {
                  setPageUpdated((prev) => !prev);
                  onClose();
               }}
            />
            <RoundedBtn
            text="Reset"
            className="btn-outline-secondary px-4 mr-3"
            type="button"
            onClick={()=>{
               setSelectedWard({label:'Select All', value:''})
               setPeriodOfContract({label:'Select All', value:''})
               setPageUpdated((prev) => !prev);
                  onClose();
            }}
         />
            <RoundedBtn
               text="Close"
               className="bg-secondary text-white px-4"
               onClick={(e) => {
                  e.preventDefault();
                  onClose();
               }}
            />
         </div>
      </div>
   );
};

export default Filter;
