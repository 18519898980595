import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from "chart.js";
import { InputWithLabel } from "../../commanComponents/inputs/InputWithLabel";
import { userRole } from "../../constants/Helper/getUserRole";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { formatDate } from "../../constants/Helper/Helpers";
import Spinner from "../../commanComponents/Spinner";

ChartJS.register(
   CategoryScale,
   LinearScale,
   BarElement,
   Title,
   Tooltip,
   Legend
);

const CollectionChart = () => {
   const [loading, setLoading] = useState(false);
   const [date, setDate] = useState();
   const [days, setDays] = useState([]);
   const [wasteUnit, setWasteUnit] = useState('')
   const [garbageData, setGarbageData] = useState([]);
   const [garbageList, setGarbageList] = useState([]);
   const [garbageInfoId, setGarbageInfoId] = useState({});

   const data = {
      labels: days,
      datasets: [
         {
            label: garbageInfoId?.label,
            data: garbageData,
            backgroundColor: "rgba(118, 185, 95, 1)",
            borderRadius: 8,
            barThickness: 10,
         },
      ],
   };

   const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
         x: {
            display: true,
            title: {
               display: true,
               text: "Day",
               color: "black",
               font: {
                  size: 14,
                  weight: "500",
               },
            },
            ticks: {
               maxRotation: 0,
               minRotation: 0,
            },
         },
         y: {
            display: true,
            title: {
               display: true,
               text: wasteUnit,
               color: "black",
               font: {
                  size: 14,
                  weight: "500",
               },
            },
            beginAtZero: true,
         },
      },
      plugins: {
         legend: {
            display: false,
         },
         tooltip: {
            enabled: true,
         },
      },
      ticks: {
         maxTicksLimit: 8,
      },
   };

   const fetch15DaysCollection = async (date) => {
      const dataToSend = {
         date,
         garbageInfoId: garbageInfoId?.value,
      };
      try {
         setLoading(true);
         const serverResponse = await commonApi.get15DaysCollection(dataToSend);
         if (serverResponse?.status === "SUCCESS") {
            if(serverResponse?.data?.length >0){
               setGarbageData(serverResponse?.data?.map((ele)=>{
                  return ele?.garbageCollected
               }));
               setDays(serverResponse?.data?.map((ele)=>{
                  return ele?.date
               }));
               setWasteUnit(serverResponse?.unit)
            }
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else {
            console.log( serverResponse?.message );
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const fetchActiveGarbage = async () => {
      try {
         setLoading(true);
         const serverResponse = await commonApi.getActiveGarbage();
         if (serverResponse?.status === "SUCCESS") {
            if (serverResponse?.result?.length > 0) {
               const garbage = serverResponse?.result?.map((data) => {
                  return { label: data?.wasteType, value: data?._id };
               });
               garbage && setGarbageInfoId(garbage[0]);
               setGarbageList(garbage);
            }
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         } else if (serverResponse?.status === "FAILED") {
            toast.error(`Failed : ${serverResponse?.message}`);
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   const fetchAdminDashboardWasteCollection = async (date) => {
      const dataToSend = {
         date,
         garbageInfoId: garbageInfoId?.value,
      };
      try {
         setLoading(true);
         const serverResponse =
            await commonApi.getAdminDashboardWasteCollection(dataToSend);
         if (serverResponse?.status === "SUCCESS") {
            if(serverResponse?.data?.length >0){
               setGarbageData(serverResponse?.data?.map((ele)=>{
                  return ele?.garbageCollected
               }));
               setDays(serverResponse?.data?.map((ele)=>{
                  return ele?.date
               }));
            }
         } else if (serverResponse?.status === "JWT_INVALID") {
            toast.info(`Failed : ${serverResponse?.message}`);
         }  else {
            console.log( serverResponse?.message );
         }
      } catch (error) {
         toast.error(`Failed : ${error.message}`);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      if (userRole() === "zonal") {
         fetch15DaysCollection(date);
      } else {
         fetchAdminDashboardWasteCollection(date);
      }
   }, [date, garbageInfoId]);

   useEffect(() => {
      fetchActiveGarbage();
   }, []);

   return (
      <div className="mt-4 doubleBarGraphWrapper big_Shadow">
         <div className="d-flex justify-content-between">
            <div>
               <h5>Collection</h5>
            </div>

            <div className="d-flex justify-content-between">
               {loading && <Spinner />}
               <div className="mr-2 ml-3" style={{width:'150px'}} title="Date filter, Check Last 15 days collection">
                  <InputWithLabel
                     label={false}
                     labelText={"date"}
                     placeholder={"Date"}
                     type="date"
                     onChange={(e) => setDate(e.target.value)}
                  />
               </div>
               <div style={{width:'150px'}} title="Filter by garbage type">
               <Select
                  options={garbageList}
                  onChange={(garbage) => {
                     setGarbageInfoId(garbage);
                  }}
                  value={garbageInfoId}
                  placeholder={"Garbage Type"}
               />
               </div>
            </div>
         </div>
         <div className="collectionchartContainer transparant_Scrollbar">
            <div
               style={{
                  minWidth: `${days && days?.length * 100}px`,
                  height: "100%",
               }}
            >
               <Bar data={data} options={options} />
            </div>
         </div>
      </div>
   );
};

export default CollectionChart;
