import React, { memo, useState, useCallback, useEffect, useMemo } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsService,
  DirectionsRenderer,
  Marker,
} from "@react-google-maps/api";
import UserMarker from "./UserMarker";
import { useSelector } from "react-redux";

const containerStyle = {
  width: "100%",
  height: "520px",
};

const center = {
  lat: 21.149539260461108,
  lng: 79.08211870155534,
};

const MapComponent = memo(({ location }) => {
  const { house } = useSelector((state) => state.liveTracking);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD2SSBFBufP4oQKR7iLc9HHUk0oX-KsoWY",
  });
  console.log(location);

  const [map, setMap] = useState(null);
  const [directionsResponses, setDirectionsResponses] = useState({});

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  // const handleDirectionsServiceCallback = useCallback((response, key) => {
  //   if (response !== null && response.status === "OK") {
  //     setDirectionsResponses((prevResponses) => {
  //       // Only update if the response is new to prevent re-rendering
  //       if (prevResponses[key] !== response) {
  //         return {
  //           ...prevResponses,
  //           [key]: response,
  //         };
  //       }
  //       return prevResponses;
  //     });
  //   } else {
  //     console.error("Directions request failed for key:", key, response);
  //   }
  // }, []);

  // Custom marker icon with proper memoization
  const customMarkerIcon = useMemo(
    () => ({
      url: require("./../../assets/icons/marker_map.png"),
      fillColor: "#EB00FF",
      scale: 0.5,
    }),
    []
  );
  const customOpenPlotIcon = useMemo(
    () => ({
      url: require("./../../assets/img/openPlot.png"),
      fillColor: "#EB00FF",
      scale: 0.5,
    }),
    []
  );

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={50}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* <UserMarker
          zoneId={zoneId}
          trackId={trackId}
          latitude={memoizedUserLatitude}
          longitude={memoizedUserLongitude}
        /> */}

      {/* {memoizedTracks.length > 0 &&
        memoizedTracks.map((locationArray, locationIndex) =>
          locationArray?.trackCords?.map((track, trackIndex) => {
            const key = `${locationIndex}-${trackIndex}`;
            const waypoints = track.slice(1, -1).map((loc) => ({
              location: { lat: loc.latitude, lng: loc.longitude },
              stopover: false,
            }));

            return (
              <React.Fragment key={key}>
                <DirectionsService
                  options={{
                    origin: {
                      lat: track[0].latitude,
                      lng: track[0].longitude,
                    },
                    destination: {
                      lat: track[track.length - 1].latitude,
                      lng: track[track.length - 1].longitude,
                    },
                    waypoints: waypoints,
                    travelMode: "DRIVING",
                  }}
                  callback={(response) =>
                    handleDirectionsServiceCallback(response, key)
                  }
                />
                {directionsResponses[key] && (
                  <DirectionsRenderer
                    options={{
                      directions: directionsResponses[key],
                      preserveViewport: true,
                      suppressMarkers: true,
                    }}
                  />
                )}
                <Marker
                  position={{
                    lat: track[0].latitude,
                    lng: track[0].longitude,
                  }}
                  icon={customMarkerIcon}
                />
                <Marker
                  position={{
                    lat: track[track.length - 1].latitude,
                    lng: track[track.length - 1].longitude,
                  }}
                  icon={customMarkerIcon}
                />
              </React.Fragment>
            );
          })
        )} */}

      {location?.map((loc) => (
        <UserMarker location={loc} />
      ))}
      {house?.map((loc) => {
        return (
          <Marker
            title={loc.ownerName}
            position={{
              lat: Number(loc.latitude),
              lng: Number(loc.longitude),
            }}
            icon={
              loc.houseType === "open plot"
                ? customOpenPlotIcon
                : customMarkerIcon
            }
          />
        );
      })}
    </GoogleMap>
  ) : null;
});

// // Prevent TrackMap from re-rendering unless trackId or zoneId change
// const areEqual = (prevProps, nextProps) => {
//   return prevProps.tracks.length === nextProps.tracks.length;
// };
// export default memo(MapComponent, areEqual);
export default MapComponent;
