import { useNavigate, useSearchParams } from "react-router-dom";
import GarbageCollectionLayout from "./GarbageCollectionLayout";
import GarbageCollectionList from "./GarbageCollectionList";
import ViewDetailsGarbageCollection from "./CommonComponent/ViewDetailsGarbageCollection";
import { commonApi } from "../../constants/apis/adminCommunication";
import { toast } from "react-toastify";
import { useEffect, useState, useRef } from "react";
import useDebounce from "../../constants/useDebounce";
import CenteredPopup from "../../commanComponents/Modals/CenteredPopup";
import GarbageCollectionFilterForm from "./CommonComponent/GarbageCollectionFilterForm";
import { checkUserTabAccess } from "../../constants/Helper/checkUserTabAccess";
import Loader from "../../commanComponents/Loader";

const CommercialGarbageCollection = () => {
  const route = useNavigate();
  const [params] = useSearchParams();
  const [garbageInfo, setGarbageInfo] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  const debouncedSearchTerm = useDebounce(searchString, 1000, setPage);

  const [selectedWardList, setSelectedWardList] = useState(null);
  const [selectedZoneList, setSelectedZoneList] = useState(null);
  const [zoneList, setZoneList] = useState([]);
  const [loading, setLoading] = useState(false);

  const garbageCollectionId = params.get("id");
  const HandleGarbageCollectionId = (house) => {
    route(`/garbage-collection/residential-garbage-collection?id=${house?._id}&houseNo=${house?.plotNo}`);
  };

  const getResidentialCollectionList = async () => {
    try {
      setLoading(true)
      const res = await commonApi.getGarbageCollectionInfo(
        page,
        "commercial",
        debouncedSearchTerm,
        selectedZoneList?.value,
        selectedWardList?.value
      );

      if (res?.status === "SUCCESS") {
        setGarbageInfo(res?.garbage);
        setTotalPages(res?.totalPages);
        setIsPopupOpen(false);
      } else if (res?.status === "JWT_INVALID") {
        toast.error(res?.message || "Please Login!");
        setGarbageInfo([]);
        setTotalPages(1);
        route("/login");
      } else {
        setGarbageInfo([]);
        setTotalPages(1);
      }
    } catch (error) {
      toast.error(error?.message || "Something Went Wrong!");
    }
    finally{
      setLoading(false)
    }
  };

  const getActiveZone = async () => {
    try {
      const res = await commonApi.getActiveZones();
      if (res?.status === "SUCCESS") {
        const options = res.result.map((zone) => ({
          value: zone._id,
          label: zone.name,
        }));
        setZoneList(options);
      } else if (res?.status === "JWT_INVALID") {
        toast.error(res?.message || "Please Log In!");
      } else {
        toast.error(res?.message || "Something Went Wrong!");
      }
    } catch (error) {
      toast.error(error?.message || "Something Went Wrong!");
    }
  };

  // Ref to track if the initial load has occurred
  const isInitialLoad = useRef(true);

  useEffect(() => {
    getActiveZone();
  }, []);

  useEffect(() => {
    if (isInitialLoad.current) {
      getResidentialCollectionList();
      isInitialLoad.current = false; // Set the ref to false after the initial load
    }
  }, [selectedZoneList]); // Trigger only on selectedZoneList change

  useEffect(() => {
    if (!isInitialLoad.current) {
      getResidentialCollectionList();
    }
  }, [page, debouncedSearchTerm]); // Trigger on page or search changes

  const handleOpenPopup = () => setIsPopupOpen(true);
  const handleClosePopup = () => setIsPopupOpen(false);

  const handleFilter = () => {
    handleClosePopup();
    getResidentialCollectionList();
  };

  
  useEffect(()=>{
    if (!checkUserTabAccess("Garbage_Collection")) {
      toast.info('You do not have permission to access Garbage Collection')
       route(-1); // redirect to previous page if user dont not have Tab Access
      return;
   }
   },[])

  return (
    <>
    {loading && <Loader />}
      {garbageCollectionId ? (
        <ViewDetailsGarbageCollection houseId={garbageCollectionId} />
      ) : (
        <GarbageCollectionLayout
          handleOpenPopup={handleOpenPopup}
          page={page}
          selectedWardList={selectedWardList}
          selectedZoneList={selectedZoneList}
          setPage={setPage}
          totalPages={totalPages}
          handleSearch={(e) => setSearchString(e.target.value)}
        >
          <GarbageCollectionList
          page={page}
            data={garbageInfo}
            HandleGarbageCollectionId={HandleGarbageCollectionId}
          />
          <CenteredPopup
            isOpen={isPopupOpen}
            onClose={handleClosePopup}
            title="Filter"
          >
            <GarbageCollectionFilterForm
              setSelectedWardList={setSelectedWardList}
              selectedWardList={selectedWardList}
              setSelectedZoneList={setSelectedZoneList}
              selectedZoneList={selectedZoneList}
              handleClick={handleFilter}
              onClose={handleClosePopup}
              zoneList={zoneList}
              setZoneList={setZoneList}
            />
          </CenteredPopup>
        </GarbageCollectionLayout>
      )}
    </>
  );
};

export default CommercialGarbageCollection;
